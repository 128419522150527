<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Drag the steps below into the correct order for the preparation of a capillary tube for
        melting point analysis of a mixture of two solids.
      </p>

      <drag-to-rank-input
        v-model="inputs.input1"
        :items="optionsShuffled"
        class="mb-0"
        style="max-width: 512px"
        prepend-text="First step"
        append-text="Last step"
      />
    </v-form>
  </div>
</template>

<script>
import {seededShuffle} from '@/courses/utils/seededShuffle';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '../inputs/DragToRankInput.vue';

export default {
  name: 'ChemUCI51LBA4Q16',
  components: {
    DragToRankInput,
    seededShuffle,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        'Add the mixed powder to the capillary tube to a height of 1-2 mm.',
        'Grind the mixture of compounds into a fine powder.',
        'Pack the mixed powder into the bottom of the capillary tube.',
        'Combine equal amounts of the known and unknown compound.',
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
